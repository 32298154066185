import { useRef } from "react";
import { useRouteLoaderData, useAsyncError } from "react-router-dom";
import { DeferredMenu } from "../..";
import { mobileMenuBtnClick } from "../../../support/client/special-events";


export function MenuContainer() {

    const btnRef = useRef();
    const menuRef = useRef();
    const { headerMenu } = useRouteLoaderData('root');
    const error = useAsyncError();

    if (error) {
        console.log('menu async error', error);
    }

    return (
        <nav className="menu-list block-list" aria-label="Main Menu">
            <div className="menu-ctrl-bar">
                <span className="menu-title">
                    <span className="display-text">Menu</span>
                </span>
                <button type="button" onClick={mobileMenuBtnClick} ref={btnRef} className="button-reset menu-toggle-btn" aria-label="Toggle Mobile Menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>

            <ul ref={menuRef} className="list-reset" role="menu">
                <DeferredMenu promised={headerMenu} />
            </ul>
        </nav>
    )

}