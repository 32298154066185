//import '../../assets/styles/footer.scss';
import { useRouteLoaderData } from "react-router-dom";
import { DeferredProvider } from '../';
import { FooterLink } from "./FooterLink";

export function PageFooter() {
    const { footerMenu } = useRouteLoaderData('root');
    const FooterFallback = () => (
        <ul>
            <li>
                <a href="https://prod.virtualagent.utoronto.ca/" className="text-link" target="_blank" rel="noopener noreferrer external" aria-label="Twitter Page - Opens in new window." title="UofT Mental Health Virtual Assistant">
                    <span aria-label="NAVI portal for mental health support">NAVI</span>
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/UofTOmbuds/" target="_blank" rel="noopener noreferrer external" aria-label="Facebook Page - Opens in new window.">
                    <span className="social-icon" alt="Twitter Page" title="Twitter">Twitter</span>
                </a>
            </li>
            <li>
                <a href="https://twitter.com/uoftombuds" target="_blank" rel="noopener noreferrer external" aria-label="Twitter Page - Opens in new window.">
                    <span className="social-icon" alt="Facebook Page" title="Facebook">Facebook</span>
                </a>
            </li>
        </ul>
    );
    const footerHandler = ({data = []}) => {
        // @note weight sorting performed here instead of on the api call. see service.js/endpoint.footerMenu for more details
        data.sort((a, b) => a.attributes.weight - b.attributes.weight);

        // loop through dynamic menu links and display in footer
        return <ul>
            {data.map(item => <FooterLink item={item} key={item.id} />)}
        </ul>
    };

    return (
        <>
            <div className="footer-links social-links">
                <DeferredProvider
                    fallback={<FooterFallback />}
                    promised={footerMenu}
                    errorElement={<div>error loading deferred footer menu data for site</div>}
                    handler={footerHandler} />
            </div>
            <div className="copyright">&copy; Office of the Ombudsperson | All rights reserved</div>
        </>
    )
}