import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { urlPrefixed } from "../../support/api/service";
import { slugify } from '../../support/tools/helpers';
import FB_icon from '../../assets/facebook-icon.svg';
import IN_icon from '../../assets/instagram-icon.svg';
import LI_icon from '../../assets/linkedin-icon.svg';
import TW_icon from '../../assets/x-icon.svg';

export function FooterLink({item}) {
    const text = item.attributes.title ?? '';
    const href = item.attributes.link.alias ?? item.attributes.link.uri ?? '#';
    const detail = item.attributes.description ?? text;
    const isExternal = !href.startsWith('/');
    const key = slugify(text);

    const icons = {
        'facebook': FB_icon,
        'twitter': TW_icon,
        'x': TW_icon,
        'instagram': IN_icon,
        'linkedin': LI_icon,
    }

    if (!isExternal) {
        return <li>
            <Link
                to={href.replace(urlPrefixed.text,'')}
                className="text-link">{text}</Link>
        </li>
    }

    else if (Object.keys(icons).includes(key)) {
        const icon = icons[key];
        return <li>
            <a
                href={href}
                className="icon-link"
                target="_blank"
                rel="noopener noreferrer external"
                aria-label={detail}>
                <img
                    src={icon}
                    className="social-icon"
                    alt={text + ' Page'}
                    title={text} />
            </a>
        </li>
    }

    else return <li>
        <a href={href} className="text-link" target="_blank" rel="noopener noreferrer external" aria-label={detail} title={detail}>
            <span aria-label={detail}>{text}</span>
        </a>
    </li>
}

FooterLink.propTypes = {
    item: PropTypes.object,
}

